import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './aktionen.module.css';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import NewsItemImages from '@mangoart/gatsby-ui/components/ezagrar/NewsItemImages';
import TeamMember from '../components/Aktionen/TeamMember';

AktionenTemplate.propTypes = {
  pathContext: PropTypes.shape().isRequired,
};

function AktionenTemplate({ data }) {
  const { t } = useTranslation('labels');

  if (!data || !data.item) {
    console.log('data empty in AktionenTemplate');
    return null;
  }
  const { item } = data;
  const { title, teaser, description, gallery, headerImage, contacts } = item;
  const modifiedText = description.replace(/\/storage\//g, 'https://ezagrar.cms.mangomedia.at/storage/');

  let pageTitle = t('aktionen.title_aktionen');
  let backToListLabel = t('aktionen.backlabel');
  let backToListLink = '/aktionen';

  return (
    <DefaultLayout>
      <div style={{ maxWidth: 2000, margin: '0 auto' }}>
        <GatsbyImage alt={title} image={getImage(headerImage)} />
      </div>
      <div className="wrap">
        <Helmet>
          <title>{title + ' | ' + pageTitle}</title>
          <meta name="description" content={teaser} />
        </Helmet>
        <div className={styles.news}>
          <div className={styles.right}>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: modifiedText }} />
            <div className={styles.allNewsItemsLink}>
              <Link to={backToListLink}>{backToListLabel}</Link>
            </div>
          </div>
          <div className={styles.left}>
            <NewsItemImages
              images={gallery.map((node) => {
                return {
                  altText: node.alttext,
                  thumb: getImage(node),
                  image: getImage(node),
                };
              })}
            />
          </div>
        </div>
        {contacts && (
          <>
            <PageTitle title={'Deine Ansprechpartner'} />
            <div className={styles.contactGrid}>
              {contacts.map((node) => {
                return <TeamMember entry={node} key={node.id} />;
              })}
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
}

export default AktionenTemplate;

export const query = graphql`
  query ($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    item: cockpitAktionen(id: { eq: $id }) {
      id
      title
      description
      headerImage {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 2000, height: 600, placeholder: NONE, quality: 100)
        }
      }
      gallery {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: NONE, quality: 100)
        }
      }
      contacts {
        id
        Name
        company
        departments {
          id
          Bezeichnung
        }
        Aufgabenbereiche
        exclude
        Email
        Telefon
        Mobiltelefon
        Fax
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 200
              height: 250
              transformOptions: { cropFocus: NORTH }
              placeholder: BLURRED
              quality: 90
            )
          }
        }
        cockpitId
      }
    }
  }
`;
